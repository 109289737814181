import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

const Footer = styled.footer`
  .footer-contact {
    font-size: 18px;
    line-height: 1.1em;
    @media (min-width: 480px) {
      font-size: 22px;
    }
  }
  .footer-bar {
    font-size: 12px;
    @media (min-width: 640px) {
      font-size: 20px;
    }
  }
`;

export default () => (
  <Footer id="contact" className="footer">
    <div className="container">
      <div className="footer-contact">
        <h2>contact</h2>
        <p>
          WOULD YOU LIKE TO TALK TO US? WE LOOK FORWARD TO HEARING FROM YOU!
        </p>
        <a className="mail-link" href="mailto:inflight@telekom.de">
          inflight@telekom.de
        </a>
      </div>
      <div className="footer-bar flex-between">
        <div>
          <ul className="list">
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/disclaimer">Disclaimer</a>
            </li>
            <li>
              <a href="/imprint">Imprint</a>
            </li>
          </ul>
          <div className="other-list">
            <span>Other links:</span>
            <ul className="list">
              <li>
                <a href="https://www.telekom.com/en" target="_blank">
                  Deutsche Telekom
                </a>
              </li>
              <li>
                <a href="https://globalcarrier.telekom.com" target="_blank">
                  Deutsche Telekom Global Carrier
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <ul className="social-networks">
            <li>
              <a
                href="https://www.youtube.com/channel/UCNq8s26_mIC12gtbjznKUQw"
                target="_blank">
                <svg className="icon icon-youtube">
                  <use xlinkHref="#icon-youtube" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/showcase/inflight-services-of-deutsche-telekom/"
                target="_blank">
                <svg className="icon icon-linkedin-square">
                  <use xlinkHref="#icon-linkedin-square" />
                </svg>
              </a>
            </li>
          </ul>
          <p className="copyright">© 2018 Deutsche Telekom AG</p>
        </div>
      </div>
    </div>
  </Footer>
);
