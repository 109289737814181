import React from 'react';
import styled from 'styled-components';


export default styled.p`
  color: ${props => props.color || '#4b4b4b'};
  font-size: 20px;
  line-height: 1.25;
  padding: 0 0.5em;
  @media (min-width: 640px) {
    font-size: 25px;
    padding: 0 2em;
  }
  @media (min-width: 960px) {
    font-size: 20px;
  }
`