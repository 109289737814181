import React from 'react';
import styled from 'styled-components';

export default styled.h2`
  color: ${props => props.color || '#e20074'};
  font-size: 32px;
  margin-bottom: 1em;
  &+ h3 {
    margin-top: -1em;
  }
  @media (min-width: 640px) {
    font-size: 40px;
  }
`;

