import React from "react";
import { createGlobalStyle } from "styled-components";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";
import Social from "./Social";
import SVGDefinitions from "./SVGDefinitions";
import * as fonts from "./Fonts";
import "./index.scss";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "telegrotesk";
    src: url(${fonts.TeleGroteskRegularWOFF2}) format("woff2"), url(${fonts.TeleGroteskRegularWOFF}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "telegrotesk";
    src: url(${fonts.TeleGroteskBoldWOFF2}) format("woff2"), url(${fonts.TeleGroteskBoldWOFF}) format("woff");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "telegrotesk";
    src: url(${fonts.TeleGroteskUltraWOFF2}) format("woff2"), url(${fonts.TeleGroteskUltraWOFF}) format("woff");
    font-weight: 900;
    font-style: normal;
  }
`;

const TemplateWrapper = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        social: allDatoCmsSocialMediaPost(
          sort: { fields: [position] }
          limit: 6
        ) {
          edges {
            node {
              headline
              url
              source
              copy
            }
          }
        }
      }
    `}
    render={data => (
      <div id="wrapper">
        <GlobalStyle />
        <SVGDefinitions />
        <Helmet
          title="Deutsche Telekom AG: IN-FLIGHT CONNECTIVITY"
          meta={[
            { name: "description", content: "Deutsche Telekom Inflight" },
            {
              name: "keywords",
              content: "flight, aircraft, wifi, telekom, deutschetelekom"
            }
          ]}
        />
        <Header location={location} />

        <main className="main">
          {children}

          {location.pathname != "/social-media" && (
            <Social posts={data.social.edges} />
          )}
        </main>

        <Footer />
      </div>
    )}
  />
);

export default TemplateWrapper;
