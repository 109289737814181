import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import H2 from './typo/H2';
import H3 from './typo/H3';
import P from './typo/P';

const C = P.withComponent('cite');
const Cite = styled(C)`
  color: #e20074;
  text-transform: uppercase;
  padding-left: 0 !important;
`;

const Q = P.withComponent('q');
const Quote = styled(Q)`
  padding-left: 0 !important;
`;

const Button = styled(Link)`
  font-size: 22px;
  padding: 0.6em;
`;
const Footer = styled.footer`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const variations = ['b-l', 't-l', 't-r', 'b-r'];

const Social = ({posts}) => (
  <section id="social-media" className="social-block pink-style">
    <div className="container">
      <h2>SOCIAL MEDIA</h2>
      <ul className="social-media-list">
        {posts.map(({node: p}, k) => (
          <li
            key={k}
            className={`arrow arrow-${
              variations[Math.floor(Math.random() * variations.length)]
            }`}>
            <a href={p.url} target="_blank">
              <span className="icon">
                <svg className="icon icon-linkedin">
                  <use xlinkHref={`#icon-${p.source}`} />
                </svg>
              </span>
              <blockquote>
                <Cite>{p.headline}</Cite>
                <Quote>{p.copy}</Quote>
              </blockquote>
            </a>
          </li>
        ))}
      </ul>

      <Footer>
        <Button to="/social-media" className="btn">
          see more posts
        </Button>
      </Footer>
    </div>
  </section>
);

export default Social;
