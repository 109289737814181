import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import logo from "./logo-telekom2022.jpg";

const Nav = styled.nav``;

class Header extends React.Component {
  state = { menu: false };

  componentWillReceiveProps(nProps) {
    const { location } = this.props;

    if (
      location.pathname !== nProps.location.pathname ||
      location.hash !== nProps.location.hash
    ) {
      this.setState({ menu: false });
    }
  }

  render() {
    const { menu } = this.state;
    return (
      <header className={`header ${menu && "nav-active"}`}>
        <div className="bar">
          <div className="container flex-between">
            <div className="logo">
              <a href="/">
                {/*<img src={logo} width="92" height="46" alt="Deutsche Telekom" />*/}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 264.58 315.65"
                  style={{ height: 46 }}
                >
                  <g fill="#ffffff">
                    <path d="M214.381 315.65v-22.192h-15.256c-26.315 0-38.393-15.643-38.393-38.665v-232.6h4.525c49.283 0 80.582 32.707 80.582 80.797v4.31h18.745V0H.004v107.3h18.745v-4.31c0-48.09 31.298-80.797 80.582-80.797h4.524v232.6c0 23.022-12.078 38.665-38.393 38.665H50.206v22.192z" />
                    <path d="M264.583 208.35h-62.914v-63.129h62.914zM62.91 208.35H-.004v-63.129H62.91z" />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
        {}
        <div className="container">
          <Nav className="main-nav">
            <a
              href="#"
              className="nav-opener"
              onClick={e => this.setState({ menu: !this.state.menu })}
            >
              <span />
              open navigation
            </a>
            <ul className="flex-between">
              <li>
                <Link activeClassName="active" exact to="/">
                  Overview
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/isp-story">
                  ISP Story
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/one-connectivity">
                  One Connectivity
                </Link>
              </li>
              <li>
                <Link activeClassName="active" to="/ean">
                  EAN
                </Link>
              </li>
              <li>
                <Link to="/#news">News</Link>
              </li>
              <li>
                <Link to="/#social-media">Social Media</Link>
              </li>
              <li>
                <Link to="/#contact">Contact</Link>
              </li>
            </ul>
            {/*}
          <div className="search">
            <a href="#"><svg className="icon icon-search"><use xlinkHref="#icon-search"></use></svg></a>
          </div>
        */}
          </Nav>
        </div>
      </header>
    );
  }
}

export default Header;
